import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    BankAndSuretyListing,
    BankAndSuretyListingParam,
    BlcAmendmentReport,
    BlcAmendmentReportParam,
    CombinedDetailParam,
    CombinedDetailSprocResult,
    CombinedSummaryParam,
    CombinedSummaryResult,
    CostToCompleteByBankReport,
    CostToCompleteByBankReportParam,
    CostToCompleteByCompanyReport,
    CostToCompleteByCompanyReportParam,
    InvoiceBankSurety,
    InvoiceBankSuretyParam,
    InvoiceByCompanyReport,
    InvoiceByCompanyReportParam,
    LcSuretyBondPricingAnalysis,
} from '../../models';
import { environment } from '../../../src/environments/environment';
import { SharedService } from './shared.service';

@Injectable()
export class ReportService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    getBankAndSuretyListing(bankAndSuretyListingParam: BankAndSuretyListingParam): Observable<BankAndSuretyListing[]> {
        const opts = this.sharedService.getOptsJsonUtf8();
        const body = JSON.stringify(bankAndSuretyListingParam);
        return this.http.post<BankAndSuretyListing[]>(`${environment.baseUrl}reports/bankandsuretylisting/`, body, opts);
    }

    getBlcAmendment(param: BlcAmendmentReportParam): Observable<BlcAmendmentReport[]> {
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<BlcAmendmentReport[]>(`${environment.baseUrl}reports/blcamendment`, body, opts);
    }

    getCombinedDetailReport(param: CombinedDetailParam): Observable<CombinedDetailSprocResult[]> {
        const getUrl = `${environment.baseUrl}reports/combinedDetail`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<CombinedDetailSprocResult[]>(getUrl, body, opts);
    }

    getCombinedSummaryReport(param: CombinedSummaryParam): Observable<CombinedSummaryResult[]> {
        const getUrl = `${environment.baseUrl}reports/combinedSummary`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<CombinedSummaryResult[]>(getUrl, body, opts);
    }

    getCostToCompleteByBank(param: CostToCompleteByBankReportParam): Observable<CostToCompleteByBankReport[]> {
        const getUrl = `${environment.baseUrl}reports/costtocompletebybank`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<CostToCompleteByBankReport[]>(getUrl, body, opts);
    }

    getCostToCompleteByCompany(param: CostToCompleteByCompanyReportParam): Observable<CostToCompleteByCompanyReport[]> {
        const getUrl = `${environment.baseUrl}reports/costtocompletebycompany`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<CostToCompleteByCompanyReport[]>(getUrl, body, opts);
    }

    getInvoiceBankSurety(param: InvoiceBankSuretyParam): Observable<InvoiceBankSurety[]> {
        const getUrl = `${environment.baseUrl}reports/invoicebanksurety`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<InvoiceBankSurety[]>(getUrl, body, opts);
    }

    getInvoiceByCompany(param: InvoiceByCompanyReportParam): Observable<InvoiceByCompanyReport[]> {
        const getUrl = `${environment.baseUrl}reports/invoicebycompany`;
        const opts = this.sharedService.getOptsJson();
        const body = JSON.stringify(param);
        return this.http.post<InvoiceByCompanyReport[]>(getUrl, body, opts);
    }

    getLcSuretyBondPricingAnalysisReport(date: string): Observable<LcSuretyBondPricingAnalysis[]> {
        const getUrl = `${environment.baseUrl}reports/lcSuretyBondPricingAnalysis/${date.replace(/\//g, '-')}`;
        return this.http.get<LcSuretyBondPricingAnalysis[]>(getUrl);
    }
}
